/**
 * Hook for getting and managing user geolocation
 *
 * Returns position data, formatted coordinates, error state, and welcome popup control.
 * Falls back to default Illinois coordinates if geolocation fails or is unavailable.
 * Shows welcome popup when using fallback location to let user input custom location.
 *
 * @returns {Object}
 * - latitude/longitude: Raw position values
 * - coords: Formatted {lat, lng} for map consumption
 * - error: Error message if geolocation fails
 * - showPopup/setShowPopup: Welcome modal state control
 */
import { useState, useEffect, useCallback, useMemo } from "react";

export const FALLBACK_POSITION = {
  latitude: 40.703545,
  longitude: -89.579086,
};

export default function usePosition() {
  const [position, setPosition] = useState(null);
  const [manualCoords, setManualCoords] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [showPopup, setShowPopup] = useState(false);

  const handleError = useCallback((error) => {
    setPosition(FALLBACK_POSITION);
    setShowPopup(true);
    setError(error.message);
    setLoading(false);
  }, []);

  const coords = useMemo(() => {
    if (manualCoords) return manualCoords;
    return {
      lat: position?.latitude ?? FALLBACK_POSITION.latitude,
      lng: position?.longitude ?? FALLBACK_POSITION.longitude,
    };
  }, [position, manualCoords]);

  const setCoords = useCallback((newCoords) => {
    setManualCoords(newCoords);
  }, []);

  useEffect(() => {
    if (!navigator.geolocation) {
      handleError({ message: "Geolocation not supported" });
      return;
    }

    // Set a timeout to use fallback position if geolocation takes too long
    const timeoutId = setTimeout(() => {
      handleError({ message: "Geolocation timeout" });
    }, 5000);

    const watchId = navigator.geolocation.watchPosition(
      ({ coords }) => {
        clearTimeout(timeoutId);
        setPosition({
          latitude: coords.latitude,
          longitude: coords.longitude,
        });
        setLoading(false);
      },
      handleError,
      { enableHighAccuracy: true }
    );

    return () => {
      clearTimeout(timeoutId);
      navigator.geolocation.clearWatch(watchId);
    };
  }, [handleError]);

  return {
    loading,
    error,
    showPopup,
    setShowPopup,
    setCoords,
    ...position,
    coords,
  };
}
