import React, { useContext, useState } from "react";
import { Link as RouterLink } from "react-router-dom";
import { db } from "services/firebase";
import QueuePatient from "./QueuePatient";
import {
  Alert,
  Box,
  CircularProgress,
  FormControlLabel,
  Link,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import { AuthContext } from "context/Auth";
import MembershipScreen from "components/dashboard/MembershipScreen";

const WaitingRoom = ({ data, setData }) => {
  const { subscription } = useContext(AuthContext);
  const [waitingRoomLoading, setWaitingRoomLoading] = useState(false);

  const setWaitingRoomCap = (event) => {
    const value = event.target.value;

    setData({ ...data, queueCap: value });

    db.collection("locations")
      .doc(String(data.id))
      .update({
        queueCap: value,
      })
      .then(() => {
        console.log("success");
      });
  };

  const deletePatient = (patient) => {
    setWaitingRoomLoading(true);
    let newQueue = [...data.queue];
    const index = newQueue.indexOf(patient);
    newQueue.splice(index, 1);

    const batch = db.batch();

    batch.update(db.collection("locations").doc(String(data.id)), {
      queue: newQueue,
    });

    batch.commit().then(() => {
      console.log("deleted");
      setWaitingRoomLoading(false);
    });
  };

  const textPatient = (patient, type) => {
    setWaitingRoomLoading(true);

    const batch = db.batch();

    let newQueue = [...data.queue];
    const index = newQueue.indexOf(patient);
    const updatedPatient =
      type === "call"
        ? { ...patient, called: Date.now() }
        : { ...patient, registered: Date.now() };
    newQueue[index] = updatedPatient;

    batch.update(db.collection("locations").doc(String(data.id)), {
      queue: newQueue,
    });

    const message =
      type === "call"
        ? `${data.title} is ready for you to come in. The address is ${data.address}. Show this text to the welcome desk. You are "Patient ${patient.id}".`
        : `Please expect a registration phone call shortly from ${data.title}.`;

    batch.set(db.collection("messages").doc(), {
      to: patient.phone,
      body: message,
    });

    batch.commit().then(() => {
      setWaitingRoomLoading(false);
      setData({ ...data, queue: newQueue });
    });
  };

  const toggleWaitingRoom = () => {
    if (!subscription) return;

    const value = !data?.queueEnabled;

    setData({ ...data, queueEnabled: value });

    db.collection("locations")
      .doc(String(data.id))
      .update({
        queueEnabled: value,
      })
      .then(() => {
        console.log("success");
      });
  };

  return (
    <div className="inner">
      <Typography
        variant="h4"
        component="h2"
        sx={{ mt: { xs: 1, sm: 5 }, mb: 2 }}
      >
        Your Clinic's Queue
      </Typography>

      <Typography variant="body" sx={{ display: "block", mt: 1, mb: 4 }}>
        Have patients virtually register to reserve their spot, and let them
        know when they're ready to be seen via text message.
      </Typography>

      <MembershipScreen subscription={subscription} />

      {!data.id ? (
        <CircularProgress />
      ) : (
        <Box>
          {data.healthcare_que?.enabled ? null : (
            <Alert severity="info" sx={{ mb: 2 }}>
              To get the most out of the Virtual Queue, enable{" "}
              <Link component={RouterLink} to="/dashboard/sequence">
                Virtual Registration
              </Link>{" "}
              to pre-register patients via text before they arrive and save
              time.
            </Alert>
          )}

          <FormControlLabel
            value="start"
            control={
              <Switch
                color="primary"
                onChange={toggleWaitingRoom}
                defaultChecked={data?.queueEnabled}
                disabled={!subscription}
              />
            }
            label="Use virtual waiting room?"
            labelPlacement="start"
            sx={{ ml: 0, mb: 2 }}
          />

          {waitingRoomLoading ? <CircularProgress /> : null}

          {data?.queueEnabled ? (
            <Box sx={{ maxWidth: 520, mb: 2 }}>
              <TextField
                id="cap"
                name="cap"
                label="Maximum Queue"
                type="number"
                variant="standard"
                onChange={setWaitingRoomCap}
                defaultValue={data?.queueCap}
                fullWidth
                InputLabelProps={{ shrink: true }}
                sx={{ mb: 2 }}
              />

              {data?.queue?.length ? (
                <div>
                  {data.queue.map((patient) => (
                    <QueuePatient
                      key={patient.date}
                      data={data}
                      setData={setData}
                      patient={patient}
                      textPatient={textPatient}
                      deletePatient={deletePatient}
                    />
                  ))}
                </div>
              ) : (
                <Alert severity="info">
                  No patients are currently in queue.
                </Alert>
              )}
            </Box>
          ) : null}
        </Box>
      )}
    </div>
  );
};

export default WaitingRoom;
