import React from "react";
import algoliasearch from "algoliasearch/lite";
import { InstantSearch } from "react-instantsearch";
import { useInstantSearch, useSearchBox } from "react-instantsearch";

import capitalize from "../utils/helpers/capitalize";
import { Controller } from "react-hook-form";
import { Autocomplete, CircularProgress, TextField } from "@mui/material";

const searchClient = algoliasearch(
  "9PKBQRNS3S",
  "37e874914a0f2292fbd52d7f6e1573b3"
);

const EmergencySearch = ({ control, errors }) => {
  return (
    <InstantSearch searchClient={searchClient} indexName="locations">
      <MuiAutocomplete control={control} errors={errors} />
    </InstantSearch>
  );
};

const MuiAutocomplete = ({ control, errors }) => {
  const { query, refine } = useSearchBox();
  const { results, status } = useInstantSearch();

  return (
    <Controller
      control={control}
      name="emergency_location"
      rules={{ required: "Facility address is required." }}
      render={({ field: { onChange, value } }) => (
        <Autocomplete
          id="emergency_location"
          options={results.hits}
          onChange={(event, item) => {
            onChange(item);
          }}
          value={value || null}
          loading={status === "loading"}
          noOptionsText={"No results found"}
          getOptionLabel={(option) =>
            capitalize(
              `${option.title}, ${option.city}, ${option.state} ${option.zip}`
            ) || ""
          }
          isOptionEqualToValue={(option, value) => option.id === value.id}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Facility Name/Address"
              InputLabelProps={{ shrink: true }}
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <>
                    {status === "loading" ? (
                      <CircularProgress color="primary" size={20} />
                    ) : null}
                    {params.InputProps.endAdornment}
                  </>
                ),
              }}
              onChange={(v) => {
                refine(v.target.value);
              }}
              variant="standard"
              helperText={errors?.emergency_location?.message}
              error={!!errors.emergency_location}
            />
          )}
        />
      )}
    />
  );
};

export default EmergencySearch;
