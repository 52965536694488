import React, { useEffect, useState } from "react";
import Legend from "./map/Legend";
import seat from "assets/images/chair.png";
import seatFilled from "assets/images/chair-filled.png";

import Label from "./styled/Label";
import { AccessTimeRounded } from "@mui/icons-material";
import { Box } from "@mui/material";

const SeatRating = ({
  register,
  schedule,
  value,
  date,
  defaultTime,
  getValues,
}) => {
  const [selected, setSelected] = useState();
  const options = [30, 60, 120, 150, 180, 240, 360];

  useEffect(() => {
    if (schedule) {
      setSelected(getValues("time-" + date));
    }
  }, []);

  useEffect(() => {
    if (value) setSelected(undefined);
  }, [value]);

  return (
    <>
      <Box sx={{ display: "flex", maxWidth: 420, width: "100%" }}>
        {options.map((option) => {
          const name = schedule ? "time-" + date : "time";
          const checked = option <= (selected || value || defaultTime);

          return (
            <Label
              $schedule={schedule}
              selected={selected === option || defaultTime}
              key={option}
            >
              <div>
                <img
                  src={checked ? seatFilled : seat}
                  alt={checked ? "Seat Filled" : "Empty Seat"}
                />
                <input
                  type="radio"
                  {...(register &&
                    register(name, {
                      onChange: (e) => {
                        setSelected(e.target.value);
                      },
                    }))}
                  value={option}
                  defaultChecked={option === defaultTime}
                />
              </div>
            </Label>
          );
        })}
        <AccessTimeRounded sx={{ width: 32 }} />
      </Box>
      <Legend schedule={schedule} />
    </>
  );
};

export default SeatRating;
