/**
 * Constants used throughout the map component.
 * Includes search radius configuration and city coordinates.
 */
export const SEARCH_RADIUS_KM = 25; // Search radius in kilometers

export const CITY_COORDINATES = {
  chicago: { lat: 41.881832, lng: -87.623177 },
  nyc: { lat: 40.73061, lng: -73.935242 },
  desmoines: { lat: 41.619549, lng: -93.598022 },
};
