import React, { memo } from "react";
import PropTypes from "prop-types";
import { Box, Grid, Paper, Typography } from "@mui/material";

const leftPanelStyles = (background, left, facility) => ({
  height: "100vh",
  maxHeight: "100vh",
  backgroundImage: `url(${background})`,
  backgroundRepeat: "no-repeat",
  backgroundSize: "cover",
  backgroundPosition: left ? "top left" : "top center",
  minHeight: "8rem",
  p: { xs: 0, md: 8 },
  textAlign: "center",
  display: "flex",
  justifyContent: facility ? { xs: "center", md: "flex-end" } : "center",
  alignItems: facility ? { xs: "center", md: "flex-start" } : "center",
});

const rightPanelStyles = {
  my: 8,
  mx: 3,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
};

const contentStyles = {
  maxWidth: "40rem",
  width: "100%",
};

const AuthWrapper = memo(
  ({ background, title = "", children, left = false, facility = false }) => (
    <Grid
      container
      component="main"
      sx={{ height: "100vh", maxHeight: "100vh" }}
    >
      <Grid
        item
        xs={12}
        sm={4}
        md={7}
        sx={leftPanelStyles(background, left, facility)}
      >
        {title && (
          <Typography variant="h3" component="h1" color="white">
            {title}
          </Typography>
        )}
      </Grid>
      <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
        <Box sx={rightPanelStyles}>
          <Box sx={contentStyles}>{children}</Box>
        </Box>
      </Grid>
    </Grid>
  )
);

AuthWrapper.propTypes = {
  background: PropTypes.string.isRequired,
  title: PropTypes.string,
  children: PropTypes.node.isRequired,
  left: PropTypes.bool,
  facility: PropTypes.bool,
};

AuthWrapper.displayName = "AuthWrapper";

export default AuthWrapper;
