import React from "react";
import { Controller } from "react-hook-form";
import { TextField, FormHelperText, List } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { formValidation } from "validation/formValidation";
import {
  ForumRounded,
  MedicalServicesRounded,
  PaidRounded,
} from "@mui/icons-material";
import NavListItem from "components/common/NavListItem";
import PrivacyAndTerms from "../layout/PrivacyAndTerms";

const RegistrationForm = ({
  control,
  errors,
  loading,
  firebaseErrors,
  handleSubmit,
  onSubmit,
  branding,
}) => {
  const FeaturesList = () => (
    <List dense={true}>
      <NavListItem
        icon={<MedicalServicesRounded />}
        text="Case Review"
        secondary="Present a case, develop a management plan."
      />

      <NavListItem
        icon={<ForumRounded />}
        text="Subject Matter"
        secondary="Access Medical SuperIntelligence at a professional level."
      />

      <NavListItem
        icon={<PaidRounded />}
        text="Pay as you go"
        secondary="Sign up for $19/day, $59/month, or $489/year."
      />
    </List>
  );

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Controller
        name="name"
        control={control}
        defaultValue={""}
        rules={formValidation.name}
        render={({ field }) => (
          <TextField
            id="name"
            label="First Name"
            type="text"
            InputLabelProps={{ shrink: true }}
            variant="standard"
            fullWidth
            error={!!errors?.name}
            helperText={errors?.name?.message}
            sx={{ pb: 2 }}
            {...field}
          />
        )}
      />

      <Controller
        name="email"
        control={control}
        defaultValue={""}
        rules={formValidation.email}
        render={({ field }) => (
          <TextField
            id="email"
            label="Email"
            type="email"
            InputLabelProps={{ shrink: true }}
            variant="standard"
            fullWidth
            error={!!errors?.email}
            helperText={errors?.email?.message}
            sx={{ pb: 2 }}
            {...field}
          />
        )}
      />

      <Controller
        name="password"
        control={control}
        defaultValue={""}
        rules={formValidation.password}
        render={({ field }) => (
          <TextField
            id="password"
            label="Password"
            type="password"
            InputLabelProps={{ shrink: true }}
            variant="standard"
            fullWidth
            error={!!errors?.password}
            helperText={errors?.password?.message}
            sx={{ pb: 1 }}
            {...field}
          />
        )}
      />

      {firebaseErrors && (
        <FormHelperText error={true}>{firebaseErrors}</FormHelperText>
      )}

      <FeaturesList />

      <PrivacyAndTerms control={control} errors={errors} />

      <LoadingButton
        type="submit"
        loading={loading}
        disabled={loading}
        variant="contained"
        fullWidth
        size="large"
        sx={{ mt: 2, mb: 2 }}
      >
        Sign Up
      </LoadingButton>
    </form>
  );
};

export default RegistrationForm;
