import React from "react";
import { Navigate } from "react-router";
import bgFacility from "assets/images/backgrounds/bgFacility.jpg";
import FacilityRegisterForm from "components/auth/FacilityRegisterForm";
import Loading from "components/Loading";
import AuthWrapper from "components/auth/layout/AuthWrapper";
import LogoLarge from "components/styled/LogoLarge";
import { useAuth } from "hooks/useAuth";

const FacilityRegisterPage = () => {
  const { isAuthenticated, userLoading } = useAuth();

  // Show loading state while checking auth
  if (userLoading) {
    return <Loading />;
  }

  // Redirect to dashboard if already authenticated
  if (isAuthenticated) {
    return <Navigate to="/dashboard" />;
  }

  return (
    <AuthWrapper background={bgFacility} title="Get on the Map" left facility>
      <LogoLarge />
      <FacilityRegisterForm />
    </AuthWrapper>
  );
};

export default FacilityRegisterPage;
