import React from "react";
import ResetPasswordForm from "./reset-password/ResetPasswordForm";
import ResetPasswordFooter from "./reset-password/ResetPasswordFooter";

const ResetPassword = () => {
  return (
    <>
      <ResetPasswordForm />
      <ResetPasswordFooter />
    </>
  );
};

export default ResetPassword;
