import React, { useState, useContext, useEffect } from "react";
import "firebase/compat/functions";
import Pricing from "./Pricing";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "context/Auth";
import {
  Box,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@mui/material";
import {
  AdsClickRounded,
  CalendarMonthRounded,
  GroupsRounded,
  ScheduleRounded,
  SmartphoneRounded,
} from "@mui/icons-material";

const Upgrade = ({ uid, role }) => {
  const { user, userData, subscription, userLoading } = useContext(AuthContext);
  const navigate = useNavigate();

  if (subscription) navigate("/dashboard");

  return (
    <Box>
      <Typography
        variant="h4"
        component="h2"
        sx={{ mt: { xs: 1, sm: 5 }, mb: 2 }}
      >
        Upgrade
      </Typography>

      <Typography variant="body" sx={{ display: "block", mt: 1, mb: 2 }}>
        Upgrade to HLTHDSK Plus to access all our features and supercharge your
        facility.
      </Typography>

      <List sx={{ mb: 2 }}>
        <ListItem>
          <ListItemIcon>
            <ScheduleRounded color="primary" />
          </ListItemIcon>
          <ListItemText primary="Set your current waiting room volume" />
        </ListItem>

        <ListItem>
          <ListItemIcon>
            <CalendarMonthRounded color="primary" />
          </ListItemIcon>
          <ListItemText primary="Schedule your estimated waiting room volume days and weeks in advance" />
        </ListItem>

        <ListItem>
          <ListItemIcon>
            <GroupsRounded color="primary" />
          </ListItemIcon>
          <ListItemText primary="Manage your virtual queue" />
        </ListItem>

        <ListItem>
          <ListItemIcon>
            <SmartphoneRounded color="primary" />
          </ListItemIcon>
          <ListItemText primary="Send your patients a text when you're ready to see them" />
        </ListItem>

        <ListItem>
          <ListItemIcon>
            <AdsClickRounded color="primary" />
          </ListItemIcon>
          <ListItemText primary="Get a free month to advertise your facility in a ZIP code of your choice" />
        </ListItem>
      </List>

      <Pricing uid={uid} role={userData?.role} subscription={subscription} />
    </Box>
  );
};

export default Upgrade;
