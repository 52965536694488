import React, { useState, useEffect } from "react";
import firebaseApp, { db } from "services/firebase";
import firebase from "firebase/compat/app";

export const AuthContext = React.createContext();

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState();
  const [subscription, setSubscription] = useState();
  const [userData, setUserData] = useState();
  const [loading, setLoading] = useState(true);

  const getData = (uid) => {
    return db
      .collection("users")
      .doc(uid)
      .onSnapshot((user) => {
        setUserData(user.data());
      });
  };

  useEffect(() => {
    let unsub;

    let authListener = firebaseApp.auth().onAuthStateChanged(async (user) => {
      setUser(user);

      await firebaseApp.auth().currentUser?.getIdToken(true);
      const decodedToken = await firebaseApp
        .auth()
        .currentUser?.getIdTokenResult();

      let role = decodedToken?.claims?.stripeRole;

      setSubscription(role);
      setLoading(false);

      if (user) unsub = getData(user.uid);
    });

    return () => {
      authListener();
      if (unsub) unsub();
    };
  }, [user]);

  const handleLogout = async () => {
    await firebase.auth().signOut();
  };

  return (
    <AuthContext.Provider
      value={{
        user,
        subscription,
        userData,
        userLoading: loading,
        logout: handleLogout,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
