import React from "react";
import { Routes, Route } from "react-router-dom";
import { Elements } from "@stripe/react-stripe-js";
import { stripePromise } from "config/stripe";
import { LocationProvider } from "context/Location";
import Dashboard from "components/Dashboard";
import PatientRegistration from "components/vaccine/queue/PatientRegistration";
import PatientQuestionnaire from "components/vaccine/queue/PatientQuestionnaire";

// Map pages
import MapPage from "pages/map/MapPage";

// Auth pages
import FacilityRegisterPage from "pages/auth/FacilityRegisterPage";
import PatientRegisterPage from "pages/auth/PatientRegisterPage";
import ProfessionalRegisterPage from "pages/auth/ProfessionalRegisterPage";
import LoginPage from "pages/auth/LoginPage";
import ResetPasswordPage from "pages/auth/ResetPasswordPage";
import PrivacyPolicy from "components/auth/documents/PrivacyPolicy";

import InfoDump from "components/InfoDump";
import TermsOfUse from "components/auth/documents/TermsOfUse";
import Baa from "components/auth/documents/baa";
import Verify from "components/dashboard/Verify";

export const AppRoutes = () => (
  <Routes>
    <Route path="/info_dump" element={<InfoDump />} />
    <Route
      path="/dashboard/*"
      element={
        <Elements stripe={stripePromise}>
          <LocationProvider>
            <Dashboard />
          </LocationProvider>
        </Elements>
      }
    />
    <Route path="/msi/register" element={<PatientRegisterPage />} />
    <Route path="/pro/register" element={<ProfessionalRegisterPage />} />
    <Route path="/register" element={<FacilityRegisterPage />} />
    <Route path="/login" element={<LoginPage />} />
    <Route path="/reset-password" element={<ResetPasswordPage />} />
    <Route path="/registration/*" element={<PatientRegistration />} />
    <Route
      path="/vaccine/registration"
      element={<PatientRegistration vaccine />}
    />
    <Route
      path="/vaccine/questionnaire"
      element={<PatientQuestionnaire vaccine />}
    />
    <Route path="/" element={<MapPage />} />
    <Route path="/account" element={<Verify />} />
    <Route path="/privacy-policy" element={<PrivacyPolicy />} />
    <Route path="/terms-of-use" element={<TermsOfUse />} />
    <Route path="/baa" element={<Baa />} />
    <Route path="*" element={<MapPage />} />
  </Routes>
);
