import React from "react";
import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
  Button,
  Typography,
} from "@mui/material";

/**
 * Displays a table of available ZIP codes for advertising
 * @param {Object} props
 * @param {Array} props.nearbyZips - Nearby ZIP codes with population data
 * @param {Array} props.existingZips - ZIP codes already being advertised in
 * @param {boolean} props.canUseFreeTrial - Whether user can use free trial
 * @param {Function} props.getZipPrice - Function to calculate ZIP code price
 * @param {Function} props.onAddZip - Handler for adding a new ZIP code
 */
const AvailableZipsTable = ({
  nearbyZips,
  existingZips,
  canUseFreeTrial,
  getZipPrice,
  onAddZip,
}) => {
  return (
    <>
      <Typography variant="h6" sx={{ mt: 4, mb: 2 }}>
        ZIP Codes Near You
      </Typography>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }}>
          <TableHead>
            <TableRow>
              <TableCell>ZIP Code</TableCell>
              <TableCell>Population</TableCell>
              <TableCell>Population-based Monthly Fee</TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {nearbyZips?.map((zip) => {
              if (existingZips.includes(String(zip.zip))) return null;

              return (
                <TableRow key={zip.zip}>
                  <TableCell>{zip.zip}</TableCell>
                  <TableCell>
                    {zip.population.toLocaleString("en-US")}
                  </TableCell>
                  <TableCell>
                    {canUseFreeTrial ? (
                      <strong>FREE</strong>
                    ) : (
                      `$${getZipPrice(zip.population)}.00`
                    )}
                  </TableCell>
                  <TableCell align="right">
                    <Button
                      onClick={() => onAddZip(zip)}
                      color="primary"
                      size="small"
                    >
                      {canUseFreeTrial ? "Advertise" : "Purchase"}
                    </Button>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default AvailableZipsTable;
