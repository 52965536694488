import React, { useContext, useEffect, useState } from "react";
import firebaseApp, { db } from "services/firebase";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Alert,
  Link,
} from "@mui/material";
import ZipTable from "./ZipTable";
import AddZipCode from "./AddZipCode";
import { AuthContext } from "context/Auth";

const PromoteFacilitiesSettings = ({
  location,
  switchToAddress,
  switchToBranding,
  open,
  close,
}) => {
  const { user, userData, subscription, canUseFreeTrial } =
    useContext(AuthContext);

  const [loading, setLoading] = useState(true);
  const [addOpen, setAddOpen] = useState(false);
  const [zipsAdded, setZipsAdded] = useState(false);
  const [subLoading, setSubLoading] = useState(true);
  const [adSubscription, setAdSubscription] = useState();
  const [zips, setZips] = useState([]);
  const [prices, setPrices] = useState([]);
  const [nearbyZips, setNearbyZips] = useState([]);

  const getPrices = () => {
    db.collection("plans")
      .where("active", "==", true)
      .where("metadata.id", "==", "advertising")
      .get()
      .then((querySnapshot) =>
        querySnapshot.forEach(async (doc) => {
          const productData = doc.data();

          db.collection("plans")
            .doc(doc.id)
            .collection("prices")
            .where("active", "==", true)
            .get()
            .then((priceSnap) => {
              let priceList = [];

              priceSnap.forEach((price) => {
                const priceData = price.data();

                const newPrice = {
                  id: price.id,
                  price: (priceData.unit_amount / 100).toFixed(0),
                  interval: priceData.interval,
                };

                priceList.push(newPrice);
              });

              setPrices(priceList.sort((a, b) => a.price - b.price));
            });
        })
      );
  };

  // Check to see if we have a Stripe subscription yet, if yes, then no need to go through the whole Stripe checkout process again
  const getSubscription = async () => {
    if (!user) return;

    // Only proceed if we haven't already loaded subscription data
    if (subLoading === true) {
      try {
        const subscriptionsRef = db
          .collection("users")
          .doc(user.uid)
          .collection("subscriptions");
        const allActiveSubscriptions = await subscriptionsRef
          .where("status", "==", "active")
          .get();

        if (allActiveSubscriptions.docs.length > 0) {
          const adSubscriptionDoc = allActiveSubscriptions.docs.find((doc) => {
            const data = doc.data();

            return data.metadata && Object.keys(data.metadata).length > 0;
          });

          if (adSubscriptionDoc) {
            setAdSubscription(adSubscriptionDoc.data());
          } else {
            setAdSubscription(null);
          }
        } else {
          setAdSubscription(null);
        }
      } catch (error) {
        console.error("Error fetching subscription:", error);
        setAdSubscription(null);
      } finally {
        setSubLoading(false);
      }
    }
  };

  const getZips = (uid) => {
    try {
      // Query all 'ads' subcollections where 'userId' matches and status is not pending
      const adsQuery = db
        .collectionGroup("ads")
        .where("uid", "==", uid)
        .where("status", "!=", "pending");

      // Create a real-time listener for the query
      const unsubscribe = adsQuery.onSnapshot(
        (querySnapshot) => {
          const ads = [];
          querySnapshot.forEach((doc) => {
            ads.push({
              id: doc.id,
              ...doc.data(),
            });
          });

          console.log(`Found ${ads.length} ads for user ${uid}`);
          setZips(ads || []);
          setLoading(false);
        },
        (error) => {
          console.error("Error listening to user ads:", error);
        }
      );

      // Return unsubscribe function to clean up the listener
      return unsubscribe;
    } catch (error) {
      console.error("Error setting up ads listener:", error);
    }
  };

  useEffect(() => {
    if (open) {
      if (user) getZips(user.uid);
      getPrices();
    }
  }, [open, location, user]);

  // Check if we have a promote_facilities subscription that exists yet
  useEffect(() => {
    if (open) getSubscription();
  }, [open, location, user]);

  return (
    <>
      <Dialog open={open} onClose={close} maxWidth="md" fullWidth>
        <DialogTitle>Promote Your Brand on the HLTHDSK Map</DialogTitle>

        <DialogContent>
          <DialogContentText variant="body2" sx={{ mb: 2 }}>
            Feature your institution's name, logo, tagline, and website on the
            HLTHDSK map within the ZIP codes of your choice. In addition,
            HLTHDSK will actively promote your presence in those selcted areas,
            ensuring enhanced visibility and engagement with your target
            audience.
          </DialogContentText>

          {canUseFreeTrial || !location?.group || !location?.branding ? null : (
            <Alert severity="success" sx={{ mt: 3, mb: 2 }}>
              With your HLTHDSK Plus subscription, you can advertise your
              facility at a ZIP code of your choosing for free for a month.
              Select your ZIP code below.
            </Alert>
          )}

          {zipsAdded ? (
            <Alert severity="success" sx={{ mt: 3, mb: 4 }}>
              Your advertisements on your selected ZIP codes have been posted.
            </Alert>
          ) : null}

          {!loading && !location?.group ? (
            <Alert severity="error">
              To advertise your facilities, please{" "}
              <Link
                color="inherit"
                sx={{ cursor: "pointer" }}
                onClick={switchToAddress}
              >
                enter the name of your Facility Group.
              </Link>
            </Alert>
          ) : (
            <ZipTable
              loading={loading}
              subLoading={subLoading}
              subscription={subscription}
              adSubscription={adSubscription}
              user={user}
              location={location}
              zips={zips}
              setZips={setZips}
              setAddOpen={setAddOpen}
              switchToBranding={switchToBranding}
              prices={prices}
              nearbyZips={nearbyZips}
              setNearbyZips={setNearbyZips}
            />
          )}

          <AddZipCode
            user={user}
            userData={userData}
            subscription={subscription}
            adSubscription={adSubscription}
            location={location}
            setZipsAdded={setZipsAdded}
            open={addOpen}
            close={() => setAddOpen(false)}
            prices={prices}
            zips={zips}
            setZips={setZips}
            nearbyZips={nearbyZips}
            setNearbyZips={setNearbyZips}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={close} autoFocus>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default PromoteFacilitiesSettings;
