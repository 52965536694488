import React, { useState } from "react";
import {
  Alert,
  Button,
  FormHelperText,
  TextField,
  Typography,
} from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import firebaseApp from "services/firebase";
import { LoadingButton } from "@mui/lab";
import { Link } from "react-router-dom";

const AccountResetPassword = ({ getParameter }) => {
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm();
  const [loading, setLoading] = useState(false);
  const [firebaseErrors, setFirebaseErrors] = useState("");
  const [resetComplete, setResetComplete] = useState(false);

  const onSubmit = ({ newPassword }) => {
    setLoading(true);
    setFirebaseErrors("");

    // Create user and create database entry for user/booklist
    firebaseApp
      .auth()
      .confirmPasswordReset(getParameter("oobCode"), newPassword)
      .then(function () {
        // Success
        setResetComplete(true);
      })
      .catch(function () {
        // Invalid code
      });
  };

  if (!resetComplete)
    return (
      <form onSubmit={handleSubmit(onSubmit)}>
        <Typography
          variant="body"
          sx={{ display: "block", textAlign: "left", pt: 4, pb: 3 }}
        >
          Reset your password using the form below.
        </Typography>
        <Controller
          name="newPassword"
          control={control}
          defaultValue={""}
          rules={{
            required: "Password is required.",
            minLength: {
              value: 8,
              message: "Password must be at least 8 characters long.",
            },
            pattern: {
              value:
                /(?=^.{8,}$)((?=.*\d)|(?=.*\W+))(?![.\n])(?=.*[A-Z])(?=.*[a-z]).*$/g,
              message:
                "Password must contain uppercase and lowercase letters, and at least one number or special character.",
            },
          }}
          render={({ field }) => (
            <TextField
              id="newPassword"
              label="Password"
              type="password"
              InputLabelProps={{ shrink: true }}
              variant="standard"
              fullWidth
              error={!!errors?.newPassword}
              helperText={errors?.newPassword?.message}
              sx={{ pb: 1 }}
              {...field}
            />
          )}
        />

        {firebaseErrors ? (
          <FormHelperText error={true}>{firebaseErrors}</FormHelperText>
        ) : null}

        <LoadingButton
          type="submit"
          loading={loading}
          disabled={loading}
          variant="contained"
          fullWidth
          size="large"
          sx={{ mt: 2, mb: 3 }}
        >
          Reset Password
        </LoadingButton>
      </form>
    );

  if (resetComplete)
    return (
      <>
        <Alert severity="success" sx={{ mt: 4, mb: 2 }}>
          Your password has been reset.
        </Alert>

        <Link to="/login">
          <Button fullWidth sx={{ mt: 1 }}>
            Go back to login
          </Button>
        </Link>
      </>
    );
};

export default AccountResetPassword;
