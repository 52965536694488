import Leaflet from "leaflet";
import React, { useState, useEffect } from "react";
import usePosition from "../../hooks/usePosition";
import Marker from "../map/Marker";
import Loading from "../Loading";
import Modal from "../map/Modal";
import { db } from "services/firebase";
import { MapContainer, TileLayer, Rectangle } from "react-leaflet";
import { useMap } from "react-leaflet/hooks";
import { Box } from "@mui/material";
import MiniMapHeader from "./MiniMapHeader";

const MapUpdater = ({ coords, updateMap, expanded, visible }) => {
  const map = useMap();

  useEffect(() => {
    if (coords) {
      updateMap(map, coords);
    }
  }, [coords]);

  useEffect(() => {
    if (visible) {
      setTimeout(function () {
        map.invalidateSize();
      }, 100);
    }
  }, [expanded, visible]);
};

const MiniMap = ({ visible, urgent, expanded }) => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const { latitude, longitude, error } = usePosition();
  const [coords, setCoords] = useState();
  const [filter, setFilter] = useState({
    facility: urgent ? "clinic" : "emergency",
  });
  const [searchLoaded, setSearchLoaded] = useState(true);
  const [subdomain, setSubdomain] = useState();
  const [rectangle, setRectangle] = useState();
  const [firstLoad, setFirstLoad] = useState(true);

  const [modalOpen, setModalOpen] = useState({});
  const [modalVisible, setModalVisible] = useState(false);
  const [branding, setBranding] = useState();

  const updateMap = (map, newCoords) => {
    setSearchLoaded(false);

    map.setView(coords, map.getZoom());

    // bounds of map
    const bounds = {
      north: newCoords.lat + 0.35,
      south: newCoords.lat - 0.35,
      east: newCoords.lng + 0.35,
      west: newCoords.lng - 0.35,
    };

    setRectangle([
      [bounds.north, bounds.east],
      [bounds.south, bounds.west],
    ]);

    getLocations(bounds);
  };

  const calculateWaitingScore = (score) => {
    if (score === "") {
      return undefined;
    }

    if (score <= 1) {
      return 30;
    } else if (score <= 2) {
      return 60;
    } else if (score <= 3) {
      return 120;
    } else if (score <= 5) {
      return 150;
    } else if (score <= 7) {
      return 180;
    } else if (score <= 9) {
      return 240;
    } else {
      return 360;
    }
  };

  const getLocations = async (bounds) => {
    const latLocations = [];
    const lngLocations = [];

    await Promise.all([
      db
        .collection("locations")
        .where("lat", "<", bounds.north)
        .where("lat", ">", bounds.south)
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach(function (doc) {
            if (doc.data().lat) {
              latLocations.push(doc.data());
            }
          });
        }),
      db
        .collection("locations")
        .where("lng", "<", bounds.east)
        .where("lng", ">", bounds.west)
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach(function (doc) {
            if (doc.data().lat) {
              lngLocations.push(doc.data());
            }
          });
        }),
    ]).then(() => {
      const locations = latLocations.filter((locationLat) =>
        lngLocations.some((locationLng) => locationLat.id === locationLng.id)
      );

      // We have the results from firebase, now we just need to process the data and set to state
      const newData = locations;

      // // If there are any clinics, only show clinics. If not, show all. Only on first load & Only for the map in the chatbot!
      // if (firstLoad && !newData.find((c) => c.type === "Clinic")) {
      //   setFilter({ facility: "all", rating: 4 });
      // }
      // setFirstLoad(false);

      const timeLimit = 60 * 60 * 1000 * 3; //hours by which time will no longer be used

      // Average wait times and get other metadata and add to data
      locations.forEach((location, i) => {
        let newLocation = { ...newData[i] };
        newData[i] = newLocation;

        newLocation.email = location.email;
        newLocation.queueEnabled = location.queueEnabled;
        newLocation.queueCap = location.queueCap;
        newLocation.queueLength = location.queue?.length;

        // WIP: Very temporary solution to placing the logo, to be refined so no multiples can be placed
        if (newLocation?.branding?.logo && newLocation?.branding?.website) {
          setBranding(newLocation.branding);
        }

        const waitTimes = location.waitTimes;

        if (!waitTimes) {
          newLocation.waitScore = calculateWaitingScore(location.score);

          newData[i] = newLocation;
          // setData(newData);
          return;
        }

        if (waitTimes) {
          const sortedWaitTimes = waitTimes.reverse();

          if (waitTimes.find((time) => time.admin)) {
            const { admin } = sortedWaitTimes.find((time) => time.admin);

            newLocation.admin = admin;
          }

          if (waitTimes.find((time) => time.dashboard)) {
            const {
              customPhone,
              telehealth,
              beds,
              icu,
              lab,
              xray,
              ultrasound,
              ct,
              mri,
            } = sortedWaitTimes.find((time) => {
              return time.dashboard;
            });

            newLocation.telehealth = telehealth;
            newLocation.customPhone = customPhone;
            newLocation.beds = beds;
            newLocation.icu = icu;
            newLocation.lab = lab;
            newLocation.xray = xray;
            newLocation.ultrasound = ultrasound;
            newLocation.ct = ct;
            newLocation.mri = mri;
          }

          // Take out times > 3 hours ago
          const validWaitTimes = waitTimes.filter(
            (time) =>
              new Date() - time.date < timeLimit && new Date() > time.date
          );

          // If there are no valid wait times, let's get the data from 18b and 22
          if (!validWaitTimes.length) {
            newLocation.waitScore = calculateWaitingScore(location.score);
            return;
          }

          const adminWaitTimes = validWaitTimes.filter((time) => time.admin);

          // Average times if user-submitted, otherwise grab first time
          const finalWaitTime = adminWaitTimes.length
            ? sortedWaitTimes.find((time) => time.waitTime).waitTime
            : validWaitTimes.reduce((sum, time) => {
                return sum + Number(time.waitTime);
              }, 0) / validWaitTimes.length;

          newLocation.lastUpdated = adminWaitTimes.length
            ? adminWaitTimes[0].date
            : validWaitTimes[0].date;

          newLocation.averageWaitTime = finalWaitTime;
          newData[i] = newLocation;
        }
      });

      setData(newData);
      setSearchLoaded(true);
      setLoading(false);
    });
  };

  // Onload, check if we're on a subdomain...
  useEffect(() => {
    const href = window.location.href.split("/");
    switch (href[href.length - 1]) {
      case "chicago":
        setSubdomain("chicago");
        setCoords({ lat: 41.881832, lng: -87.623177 });
        break;
      case "nyc":
        setSubdomain("nyc");
        setCoords({ lat: 40.73061, lng: -73.935242 });
        break;
      case "desmoines":
        setSubdomain("desmoines");
        setCoords({ lat: 41.619549, lng: -93.598022 });
        break;
      case "urgent":
        setFilter({ ...filter, facility: "clinic" });
        break;
      case "emergency":
        setFilter({ ...filter, facility: "emergency" });
        break;
      default:
        setSubdomain();
    }
  }, []);

  useEffect(() => {
    // Get locations when geolocation has loaded

    if (latitude) {
      // bounds of map
      const bounds = {
        north: latitude + 0.35,
        south: latitude - 0.35,
        east: longitude + 0.35,
        west: longitude - 0.35,
      };

      setRectangle([
        [bounds.north, bounds.east],
        [bounds.south, bounds.west],
      ]);

      getLocations(bounds);
    }
  }, [latitude]);

  return (
    <Box
      sx={{
        height: "100%",
        width: "100%",
        display: visible ? "block" : "none",
      }}
    >
      {loading ? (
        <Loading page />
      ) : (
        <Box
          sx={{
            height: "100%",
            width: "100%",
            position: "relative",
            overflowY: "hidden",
          }}
        >
          <MapContainer
            attributionControl={false}
            style={{ height: "100%", width: "100%", zIndex: 0 }}
            center={
              (coords && [coords.lat, coords.lng]) || [latitude, longitude]
            }
            zoom={13}
            zoomControl={false}
            animate={true}
          >
            <MiniMapHeader
              data={data}
              branding={branding}
              filter={filter}
              setFilter={setFilter}
              setCoords={setCoords}
              updateMap={updateMap}
              searchLoaded={searchLoaded}
              expanded={expanded}
            />
            <TileLayer url="https://api.maptiler.com/maps/streets/{z}/{x}/{y}.png?key=Qsfg2BU2JRzkowxU2Rw0" />
            {data.map((data) => {
              return (
                <Marker
                  key={data.id}
                  data={data}
                  filter={filter}
                  setModalOpen={setModalOpen}
                  setModalVisible={setModalVisible}
                />
              );
            })}
            <Rectangle
              bounds={rectangle}
              color="#ff0000"
              fillColor="transparent"
            />
            <MapUpdater
              coords={coords}
              updateMap={updateMap}
              expanded={expanded}
              visible={visible}
            />
          </MapContainer>
          {expanded ? (
            <Modal
              modalOpen={modalOpen}
              setModalOpen={setModalOpen}
              modalVisible={modalVisible}
              setModalVisible={setModalVisible}
              userLocation={{ latitude, longitude }}
              error={error}
            />
          ) : null}
        </Box>
      )}
    </Box>
  );
};

export default MiniMap;
