import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Controller, useForm } from "react-hook-form";
import {
  TextField,
  FormHelperText,
  Button,
  Box,
  Typography,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import firebaseApp from "services/firebase";
import { useAuth } from "hooks/useAuth";

const LoginForm = ({ loginFormOpen, setLoginFormOpen }) => {
  const navigate = useNavigate();
  const { loading: authLoading } = useAuth();
  const [firebaseErrors, setFirebaseErrors] = useState("");
  const [loading, setLoading] = useState(false);

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({ mode: "onBlur" });

  const onSubmit = async ({ email, password }) => {
    setLoading(true);
    setFirebaseErrors("");

    try {
      await firebaseApp.auth().signInWithEmailAndPassword(email, password);
      navigate("/dashboard");
    } catch (error) {
      const errorMessage =
        error.code === "auth/invalid-credential"
          ? "Your email address or password is incorrect. Please try again."
          : error.message;
      setFirebaseErrors(errorMessage);
    } finally {
      setLoading(false);
    }
  };

  if (!loginFormOpen) {
    return (
      <Box sx={{ textAlign: "center" }}>
        <Typography variant="h6" sx={{ mb: 1 }}>
          Already have an account?
        </Typography>
        <Button
          variant="contained"
          onClick={() => setLoginFormOpen(true)}
          sx={{ mb: 1 }}
        >
          Log In
        </Button>
      </Box>
    );
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Controller
        name="email"
        control={control}
        defaultValue={""}
        rules={{
          required: "Email is required.",
          pattern: {
            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
            message: "Invalid email address.",
          },
        }}
        render={({ field }) => (
          <TextField
            id="email"
            label="Email"
            type="email"
            InputLabelProps={{ shrink: true }}
            variant="standard"
            fullWidth
            error={!!errors?.email}
            helperText={errors?.email?.message}
            sx={{ pb: 2 }}
            {...field}
          />
        )}
      />

      <Controller
        name="password"
        control={control}
        defaultValue={""}
        rules={{
          required: "Password is required.",
        }}
        render={({ field }) => (
          <TextField
            id="password"
            label="Password"
            type="password"
            InputLabelProps={{ shrink: true }}
            variant="standard"
            fullWidth
            error={!!errors?.password}
            helperText={errors?.password?.message}
            sx={{ pb: 1 }}
            {...field}
          />
        )}
      />

      {firebaseErrors && (
        <FormHelperText error={true}>{firebaseErrors}</FormHelperText>
      )}

      <LoadingButton
        type="submit"
        loading={loading || authLoading}
        disabled={loading || authLoading}
        variant="contained"
        fullWidth
        size="large"
        sx={{ mt: 2, mb: 3 }}
      >
        Log In
      </LoadingButton>
    </form>
  );
};

export default LoginForm;
