import React, { useEffect, useState } from "react";
import firebaseApp, { db } from "services/firebase";
import firebase from "firebase/compat/app";
import { Box, CircularProgress } from "@mui/material";
import { useAuth } from "hooks/useAuth";
import ZipTableContent from "./ZipTable/ZipTableContent";
import { getZipPrice as getZipPriceUtil } from "constants/advertising";

const ZipTable = ({
  loading,
  subscription,
  adSubscription,
  subLoading,
  user,
  location,
  zips,
  setZips,
  setAddOpen,
  switchToBranding,
  prices,
  nearbyZips,
  setNearbyZips,
}) => {
  const { canUseFreeTrial } = useAuth();
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [confirmed, setConfirmed] = useState();
  const [removingZip, setRemovingZip] = useState();
  const [nearbyZipsLoaded, setNearbyZipsLoaded] = useState(false);
  const onlyZips = zips.map((z) => z.zip);

  const getZipPrice = (pop) => {
    const price = getZipPriceUtil(pop, prices);
    return price ? price.price : "";
  };

  // Since Firebase can only support up to 30 array items, split the array into groups of 30
  const splitArray = (inputArray) => {
    const perChunk = 30;

    const result = inputArray.reduce((resultArray, item, index) => {
      const chunkIndex = Math.floor(index / perChunk);

      if (!resultArray[chunkIndex]) {
        resultArray[chunkIndex] = []; // start a new chunk
      }

      resultArray[chunkIndex].push(item);

      return resultArray;
    }, []);

    return result;
  };

  const getZipsNearby = async () => {
    // Array for the zip codes before we fetch the population
    let tempZipCodes = [];

    // Get the nearby zips from the database
    db.collection("zips_near")
      .doc(String(location.zip))
      .get()
      .then(async (doc) => {
        if (doc.data()) {
          // Convert ZIP codes to numbers to deal with type mismatch in populations database
          tempZipCodes = doc.data().near.map((z) => Number(z));
          // Split the array so we can deal with arrays longer than 30
          tempZipCodes = splitArray(tempZipCodes);
          let batches = [];
          let zipsWithPopulation = [];

          tempZipCodes.forEach((chunk) => {
            batches.push(
              db
                .collection("populations")
                .where("zip", "in", chunk)
                .get()
                .then((querySnapshot) => {
                  querySnapshot.forEach((doc) => {
                    const data = doc.data();

                    // Check if the zip is already owned
                    const isDuplicate = zips
                      .map((z) => z.zip)
                      .includes(data.zip);

                    if (data.population > 0 && !isDuplicate)
                      zipsWithPopulation.push(data);
                  });
                })
            );
          });

          await Promise.all(batches);

          setNearbyZipsLoaded(true);
          setNearbyZips(zipsWithPopulation);
        } else {
          console.log("There are no zip codes in the area.");
          setNearbyZipsLoaded(true);
        }
      });
  };

  const confirmRemoveZip = (zip) => {
    setConfirmOpen(true);
    setRemovingZip(zip);
  };

  // Remove an ad based on the uid
  async function removeAd(uid, zip) {
    setConfirmed(true);

    try {
      const response = await firebase
        .functions()
        .httpsCallable("manageAdSubscription")({
        action: "remove",
        uid,
        zip,
      });

      if (response.data.success) {
        console.log("Ad successfully removed from subscription");
        return response.data;
      }
    } catch (error) {
      console.error("Failed to remove ad:", error);
      throw error;
    }
  }

  const close = () => {
    if (confirmed) return;
    setConfirmOpen(false);
  };

  useEffect(() => {
    if (location) getZipsNearby();
  }, [location]);

  if (loading || subLoading) {
    return (
      <Box sx={{ display: "flex", justifyContent: "center" }}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <ZipTableContent
      location={location}
      zips={zips}
      setZips={setZips}
      setAddOpen={setAddOpen}
      switchToBranding={switchToBranding}
      prices={prices}
      user={user}
      nearbyZips={nearbyZips}
      nearbyZipsLoaded={nearbyZipsLoaded}
      confirmOpen={confirmOpen}
      confirmed={confirmed}
      onConfirmRemove={removeAd}
      onClose={close}
      canUseFreeTrial={canUseFreeTrial}
      onlyZips={onlyZips}
      getZipPrice={getZipPrice}
    />
  );
};

export default ZipTable;
