import React from "react";
import { Avatar, Box, Button, Grid, Typography } from "@mui/material";
import { ChevronRightRounded } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";

const InfoBox = ({ icon, title, subtitle, description, links }) => (
  <Box
    sx={{
      bgcolor: "background.paper",
      boxShadow: 1,
      borderRadius: 2,
      p: 2,
      mb: 3,
      minWidth: 300,
    }}
  >
    <Grid container spacing={2}>
      {icon ? (
        <Grid item xs={12} md={1}>
          <Avatar sx={{ bgcolor: "background.paper" }}>{icon}</Avatar>
        </Grid>
      ) : null}

      <Grid item xs={12} md={11}>
        <Typography variant="h6">{title}</Typography>
        {subtitle ? (
          <Typography
            variant="body"
            color="secondary"
            sx={{ fontWeight: "bold", mb: 1, display: "block" }}
          >
            {subtitle}
          </Typography>
        ) : null}

        <Typography variant="body2" gutterBottom>
          {description}
        </Typography>

        <Grid container gap={2}>
          {links?.map((link, i) => (
            <Grid item key={i}>
              {link.loading ? (
                <LoadingButton
                  loading={link.loadingCondition}
                  loadingPosition="end"
                  disabled={!!link.loading}
                  endIcon={<ChevronRightRounded />}
                  onClick={link.onClick}
                  sx={{ fontWeight: "bold", textTransform: "none", pl: 0 }}
                >
                  {link.title}
                </LoadingButton>
              ) : (
                <Button
                  endIcon={<ChevronRightRounded />}
                  onClick={link.onClick}
                  sx={{
                    fontWeight: "bold",
                    textTransform: "none",
                    pl: 0,
                  }}
                >
                  {link.title}
                </Button>
              )}
            </Grid>
          ))}
        </Grid>
      </Grid>
    </Grid>
  </Box>
);

export default InfoBox;
