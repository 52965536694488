import React, { useEffect, useState } from "react";
import Loading from "../../Loading";
import firebaseApp, { db } from "services/firebase";
import firebase from "firebase/compat/app";
import "firebase/compat/functions";
import "firebase/compat/storage";
import { Controller, useForm } from "react-hook-form";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
  Chip,
  Box,
  Alert,
  FormControl,
  InputLabel,
  InputAdornment,
  Input,
  FormHelperText,
  CircularProgress,
  Link,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";

import ZipPayment from "./ZipPayment";

const ZipSettings = ({ user, location, switchToBranding, open, close }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const [loading, setLoading] = useState(true);
  const [addOpen, setAddOpen] = useState(false);
  const [zips, setZips] = useState([]);

  const getZips = () => {
    if (!location) return;

    db.collection("zips")
      .where("locations", "array-contains", location.id)
      .get()
      .then((querySnapshot) => {
        let docs = [];

        querySnapshot.forEach(function (doc) {
          if (doc.data()) {
            const data = doc.data();
            const bid =
              data.bids?.filter((bid) => bid.location === location.id)[0] || {};
            bid.zip = data.zip;

            if (data.owner === location.id) bid.status = "Owned";

            docs.push(bid);
          }
        });

        setLoading(false);
        setZips(docs);
      });
  };

  useEffect(() => {
    if (open) getZips();
  }, [open, location]);

  return (
    <>
      <Dialog open={open} onClose={close} maxWidth="md" fullWidth>
        <DialogTitle>Choose ZIP Codes</DialogTitle>

        <DialogContent>
          <DialogContentText variant="body2" sx={{ mb: 2 }}>
            Your branding will appear whenever users are in your ZIP code. You
            can also show your branding in additional ZIP codes by bidding for
            them below.
          </DialogContentText>

          {loading ? (
            <Box sx={{ display: "flex", justifyContent: "center" }}>
              <CircularProgress />
            </Box>
          ) : (
            <ZipTable
              zips={zips}
              setAddOpen={setAddOpen}
              loading={loading}
              location={location}
              switchToBranding={switchToBranding}
            />
          )}

          <AddZipCode
            user={user}
            location={location}
            open={addOpen}
            close={() => setAddOpen(false)}
            zips={zips}
            setZips={setZips}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={close} autoFocus>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

const AddZipCode = ({ user, location, open, close, zips, setZips }) => {
  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    defaultValues: {
      zip: "",
      bid: 100,
    },
  });
  const [loading, setLoading] = useState(false);
  const [review, setReview] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [paymentMethod, setPaymentMethod] = useState();

  const clearForm = () => {
    reset();
    setSubmitted(false);
  };

  const onSubmit = ({ zip, bid }) => {
    if (!review) {
      setReview(true);
      return;
    }

    if (paymentMethod) {
      setLoading(true);
      const date = new Date();

      // TODO: ADD USER EMAIL AND LOCATION ID
      db.collection("zips")
        .doc(String(zip))
        .set(
          {
            bids: firebase.firestore.FieldValue.arrayUnion({
              bid: bid * 100,
              status: "Pending",
              user: user.uid,
              email: user.email,
              location: location.id,
              paymentId: paymentMethod,
              date,
            }),
            locations: firebase.firestore.FieldValue.arrayUnion(location.id),
            zip: String(zip),
            lastUpdated: date,
          },
          { merge: true }
        )
        .then(() => {
          setZips([...zips, { zip, bid: bid * 100, status: "Pending" }]);
          setSubmitted(true);
          setLoading(false);
        });
    }
  };

  return (
    <Dialog open={open} onClose={close}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogTitle>Add ZIP Code</DialogTitle>

        <DialogContent>
          <DialogContentText variant="body2" sx={{ mb: 2 }}>
            Purchase this ZIP code to have your branding shown to all users in
            the area.{" "}
            <strong>
              If you have the highest bid at the end of the month, you will be
              automatically billed for the amount of your bid.
            </strong>{" "}
            You will be sent an email at the end of the month confirming your
            advertisement if you are the highest bidder.
          </DialogContentText>

          <DialogContentText variant="body2" sx={{ mb: 2 }}>
            You will be asked to confirm your bid before you are charged.
          </DialogContentText>

          <Controller
            name="zip"
            control={control}
            rules={{
              required: "ZIP code is required.",
            }}
            render={({ field }) => (
              <TextField
                id="zip"
                label="ZIP code"
                type="number"
                InputLabelProps={{ shrink: true }}
                variant="standard"
                fullWidth
                error={!!errors?.zip}
                helperText={errors?.zip?.message}
                sx={{ mt: 2 }}
                {...field}
              />
            )}
          />

          <Controller
            name="bid"
            control={control}
            rules={{
              required: "A bid is required.",
              min: { value: 100, message: "Your bid must be at least $100." },
            }}
            render={({ field }) => (
              <FormControl fullWidth sx={{ mt: 2 }} variant="standard">
                <InputLabel htmlFor="bid" error={!!errors?.bid}>
                  Your bid
                </InputLabel>
                <Input
                  id="bid"
                  type="number"
                  startAdornment={
                    <InputAdornment position="start">$</InputAdornment>
                  }
                  error={!!errors?.bid}
                  {...field}
                />
                {errors?.bid ? (
                  <FormHelperText error={true}>
                    {errors?.bid?.message}
                  </FormHelperText>
                ) : null}
              </FormControl>
              // <TextField
              //   id="bid"
              //   label="Your bid"
              //   type="number"
              //   InputLabelProps={{ shrink: true }}
              //   variant="standard"
              //   fullWidth
              //   error={!!errors?.bid}
              //   helperText={errors?.bid?.message}
              //   sx={{ mt: 2 }}
              //   {...field}
              // />
            )}
          />

          {review ? (
            <ZipPayment
              user={user}
              paymentMethod={paymentMethod}
              setPaymentMethod={setPaymentMethod}
            />
          ) : null}

          {submitted ? (
            <Alert severity="success" sx={{ mt: 2 }}>
              Your bid has been placed. You will be notified by email at the end
              of the month.
            </Alert>
          ) : null}
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={close}>
            Close
          </Button>
          {submitted ? (
            <Button onClick={clearForm} variant="contained">
              Add Another ZIP
            </Button>
          ) : review ? (
            <LoadingButton
              loading={loading}
              type="submit"
              disabled={loading || !paymentMethod}
              autoFocus
              variant="contained"
            >
              Place Your Bid
            </LoadingButton>
          ) : (
            <Button type="submit" autoFocus variant="contained">
              Review Your Bid
            </Button>
          )}
        </DialogActions>
      </form>
    </Dialog>
  );
};

const ZipTable = ({
  zips,
  setAddOpen,
  loading,
  location,
  switchToBranding,
}) => {
  if (
    !loading &&
    location &&
    !location?.branding?.logo &&
    !location?.branding?.website
  )
    return (
      <Alert severity="error">
        Your advertising will not show until you add your branding. Please{" "}
        <Link
          color="inherit"
          sx={{ cursor: "pointer" }}
          onClick={switchToBranding}
        >
          add your branding
        </Link>
        .
      </Alert>
    );

  return (
    <Box>
      <Box sx={{ textAlign: "right" }}>
        <LoadingButton
          variant="contained"
          sx={{ mb: 2 }}
          onClick={() => setAddOpen(true)}
        >
          Add New ZIP
        </LoadingButton>
      </Box>

      {zips.length === 0 ? (
        <Alert severity="info">
          You don't currently have any ZIP codes you're advertising in.
        </Alert>
      ) : (
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>ZIP Code</TableCell>
                <TableCell>Status</TableCell>
                <TableCell>Bid</TableCell>
                {/* <TableCell>Impressions</TableCell> */}
              </TableRow>
            </TableHead>
            <TableBody>
              {zips.map((zip) => (
                <TableRow key={zip.zip}>
                  <TableCell>{zip.zip}</TableCell>
                  <TableCell>
                    <Chip
                      size="small"
                      label={zip.status}
                      color={zip.status === "Owned" ? "success" : "default"}
                      variant={zip.status === "Owned" ? "outlined" : "filled"}
                    />
                  </TableCell>
                  <TableCell>
                    {zip.bid ? `$${(zip.bid / 100).toFixed(2)}` : "-"}
                  </TableCell>
                  {/* <TableCell></TableCell> */}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </Box>
  );
};

// const TempPayment = () => {
//   const sendToPortal = async () => {
//     const functionRef = firebase
//       .app()
//       .functions("us-central1")
//       .httpsCallable("ext-firestore-stripe-payments-createPortalLink");
//     const { data } = await functionRef({
//       returnUrl: window.location.href,
//     });
//     window.location.assign(data.url);
//   };

//   return (
//     <Box sx={{ textAlign: "center", mt: 2 }}>
//       <Typography variant="body2">
//         Please add your payment info in order to advertise on HealthDesk.
//       </Typography>

//       <Button variant="contained" sx={{ mt: 2 }} onClick={sendToPortal}>
//         Add payment info
//       </Button>
//     </Box>
//   );
// };

export default ZipSettings;
