import firebase from "firebase/compat/app";
import * as geohash from "ngeohash";
import { db } from "services/firebase";
import { getAddress } from "utils/helpers/getAddress";

export const createFacilityUser = async (email, password, userData) => {
  try {
    const {
      name,
      facility: facilityType,
      address,
      location,
      emergency_location,
    } = userData;

    // First verify we can write to Firestore
    const locationId =
      facilityType === "hospital"
        ? String(emergency_location?.id)
        : db.collection("users").doc().id;

    // Verify the document can be created
    if (facilityType === "urgent-care") {
      await db.runTransaction(async (transaction) => {
        const locationRef = db.collection("locations").doc(locationId);
        const doc = await transaction.get(locationRef);
        if (doc.exists) {
          throw new Error("Location already exists");
        }
      });
    }

    // Create the auth user
    const userCredential = await firebase
      .auth()
      .createUserWithEmailAndPassword(email, password);

    const { user } = userCredential;

    // Create batch for Firestore operations
    const batch = db.batch();

    // Set up user document
    const userRef = db.collection("users").doc(user.uid);
    batch.set(userRef, {
      uid: user.uid,
      email,
      name,
      role: "facility",
      location: locationId,
      registrationDate: firebase.firestore.FieldValue.serverTimestamp(),
    });

    // Handle location data
    const locationsRef = db.collection("locations").doc(locationId);

    if (facilityType === "hospital") {
      batch.update(locationsRef, {
        users: firebase.firestore.FieldValue.arrayUnion(user.uid),
      });
    } else if (facilityType === "urgent-care") {
      const lookup = (name) =>
        address.context.find((a) => a?.id.startsWith(name))?.text;

      const formattedAddress = getAddress(address);
      const latitude = address.center[1];
      const longitude = address.center[0];
      const geopoint = new firebase.firestore.GeoPoint(latitude, longitude);
      const hash = geohash.encode(latitude, longitude);

      batch.set(locationsRef, {
        id: locationId,
        title: location,
        lat: latitude,
        lng: longitude,
        address: formattedAddress,
        city: lookup("place"),
        state: lookup("region"),
        zip: lookup("postcode"),
        type: "Clinic",
        users: firebase.firestore.FieldValue.arrayUnion(user.uid),
        pending: true,
        coordinates: geopoint,
        g: {
          geohash: hash,
          geopoint,
        },
      });
    }

    // Commit all Firestore changes
    await batch.commit();

    // Only send verification email after everything succeeds
    await user.sendEmailVerification();

    return user;
  } catch (error) {
    throw error;
  }
};
