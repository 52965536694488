import firebaseApp, { db } from "services/firebase";
import LinkedInTag from "react-linkedin-insight";

export const createProfessionalUser = async (email, password, userData) => {
  try {
    // Start by checking if we can save to Firestore first
    const userDocRef = db.collection("users").doc();

    // Verify the document can be created (optional, but helps catch permissions issues)
    await db.runTransaction(async (transaction) => {
      const doc = await transaction.get(userDocRef);
      if (doc.exists) {
        throw new Error("Document already exists");
      }
    });

    // If we get here, we can create the Firebase auth user
    const { user } = await firebaseApp
      .auth()
      .createUserWithEmailAndPassword(email, password);

    // Now save the user data
    const batch = db.batch();
    const userRef = db.collection("users").doc(user.uid);

    batch.set(userRef, {
      uid: user.uid,
      email: userData.email,
      name: userData.name,
      role: "professional",
    });

    await batch.commit();

    // Only send verification email after everything else succeeds
    await user.sendEmailVerification();

    LinkedInTag.track(2177722);

    return user;
  } catch (error) {
    // If any step fails, throw the error to be handled by the form
    throw error;
  }
};
