import React, { useState, useEffect } from "react";
import firebaseApp, { db } from "services/firebase";
import {
  Alert,
  Box,
  Button,
  CircularProgress,
  Typography,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { Link, useNavigate } from "react-router-dom";
import AccountVerifyEmail from "./account/AccountVerifyEmail";
import AccountResetPassword from "./account/AccountResetPassword";

const Wrapper = ({ children }) => (
  <Box sx={{ textAlign: "center", maxWidth: "sm", m: "auto" }}>{children}</Box>
);

export default () => {
  const [requestType, setRequestType] = useState();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  const [verified, setVerified] = useState(false);

  let navigate = useNavigate();

  const getRequestType = () => {
    const type = getParameter("mode");
    setRequestType(type);

    return type;
  };

  // Get parameters in the URL
  const getParameter = (parameter) => {
    const matches = new RegExp(`${parameter}=([^&#=]*)`).exec(
      window.location.search
    );
    if (matches) return matches[1];

    return undefined;
  };

  const handleVerifyEmail = () => {
    const code = getParameter("oobCode");

    if (!code) {
      setLoading(false);
      setError(true);
      return;
    }

    firebaseApp
      .auth()
      .applyActionCode(getParameter("oobCode"))
      .then((resp) => {
        setLoading(false);
        setVerified(true);

        setTimeout(() => {
          return navigate("/dashboard");
        }, 3000);
      })
      .catch((error) => {
        // Code is invalid or expired. Ask the user to verify their email address
        // again.
        setLoading(false);
        setError(true);
      });
  };

  const handlePasswordReset = () => {
    const code = getParameter("oobCode");

    if (!code) {
      setLoading(false);
      setError(true);
      return;
    }

    firebaseApp
      .auth()
      .verifyPasswordResetCode(code)
      .then(() => {
        // Show the password reset form
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
        setError(true);
      });
  };

  useEffect(() => {
    const requestType = getRequestType();

    if (requestType === "verifyEmail") {
      handleVerifyEmail();
    } else if (requestType === "resetPassword") {
      handlePasswordReset();
    } else {
      navigate("/dashboard");
    }
  }, []);

  if (loading)
    return (
      <Wrapper>
        <CircularProgress sx={{ mt: 8 }} />
      </Wrapper>
    );

  if (error) {
    let errorMessage;

    if (requestType === "verifyEmail")
      errorMessage =
        "Your request to verify your email has expired or the link has already been used. Please try verifying your email again.";

    if (requestType === "resetPassword")
      errorMessage =
        "Your request to reset your password has expired or the link has already been used. Please try again.";

    return (
      <Wrapper>
        <Typography variant="h4" sx={{ mt: { xs: 4, sm: 10 }, mb: 2 }}>
          Your link has expired
        </Typography>

        <Typography variant="body">
          <p>{errorMessage}</p>
        </Typography>

        <Link to="/login">
          <Button>Return to Login</Button>
        </Link>
      </Wrapper>
    );
  }

  if (requestType === "verifyEmail")
    return (
      <Wrapper>
        <AccountVerifyEmail verified={verified} />
      </Wrapper>
    );

  if (requestType === "resetPassword")
    return (
      <Wrapper>
        <AccountResetPassword getParameter={getParameter} />
      </Wrapper>
    );
};
