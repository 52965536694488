import React from "react";
import { Controller } from "react-hook-form";
import {
  TextField,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  FormHelperText,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { formValidation } from "validation/formValidation";
import PrivacyAndTerms from "../layout/PrivacyAndTerms";

const RegistrationForm = ({
  control,
  errors,
  loading,
  firebaseErrors,
  handleSubmit,
  onSubmit,
  renderFacilityFields,
}) => {
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Controller
        name="name"
        control={control}
        defaultValue=""
        rules={formValidation.name}
        render={({ field }) => (
          <TextField
            {...field}
            id="name"
            label="First Name"
            type="text"
            InputLabelProps={{ shrink: true }}
            variant="standard"
            fullWidth
            error={!!errors?.name}
            helperText={errors?.name?.message}
            sx={{ pb: 2 }}
          />
        )}
      />

      <Controller
        name="email"
        control={control}
        defaultValue=""
        rules={formValidation.email}
        render={({ field }) => (
          <TextField
            {...field}
            id="email"
            label="Email"
            type="email"
            InputLabelProps={{ shrink: true }}
            variant="standard"
            fullWidth
            error={!!errors?.email}
            helperText={errors?.email?.message}
            sx={{ pb: 2 }}
          />
        )}
      />

      <Controller
        name="password"
        control={control}
        defaultValue=""
        rules={formValidation.password}
        render={({ field }) => (
          <TextField
            {...field}
            id="password"
            label="Password"
            type="password"
            InputLabelProps={{ shrink: true }}
            variant="standard"
            fullWidth
            error={!!errors?.password}
            helperText={errors?.password?.message}
            sx={{ pb: 2 }}
          />
        )}
      />

      <FormLabel>This facility is a:</FormLabel>
      <Controller
        name="facility"
        control={control}
        defaultValue=""
        rules={formValidation.facility}
        render={({ field: { onChange, value } }) => (
          <RadioGroup
            name="facility"
            value={value}
            onChange={onChange}
            sx={{ pb: 1 }}
          >
            <FormControlLabel
              value="hospital"
              label="Emergency Department (must be open 24 hours)"
              control={<Radio />}
            />
            <FormControlLabel
              value="urgent-care"
              label="Clinic/Immediate Care"
              control={<Radio />}
            />
          </RadioGroup>
        )}
      />

      {renderFacilityFields()}

      {firebaseErrors && (
        <FormHelperText error={true}>{firebaseErrors}</FormHelperText>
      )}

      <PrivacyAndTerms control={control} errors={errors} />

      <LoadingButton
        type="submit"
        loading={loading}
        disabled={loading}
        variant="contained"
        fullWidth
        size="large"
        sx={{ mt: 2, mb: 2 }}
      >
        Sign Up
      </LoadingButton>
    </form>
  );
};

export default RegistrationForm;
