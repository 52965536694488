import React from "react";
import { TextField } from "@mui/material";
import { IMaskInput } from "react-imask";
import { forwardRef } from "react";

const PhoneMaskAdapter = forwardRef(function PhoneMaskAdapter(props, ref) {
  const { onChange, ...other } = props;
  return (
    <IMaskInput
      {...other}
      mask="000 000 0000"
      definitions={{
        0: /[0-9]/,
      }}
      inputRef={ref}
      onAccept={(value) =>
        onChange({
          target: { name: props.name, value: value.replace(/\s/g, "") },
        })
      }
      overwrite
    />
  );
});

const PhoneInput = forwardRef(function PhoneInput(props, ref) {
  const { onChange, value, error, helperText, ...other } = props;

  return (
    <TextField
      {...other}
      value={value}
      onChange={onChange}
      variant="standard"
      fullWidth
      error={error}
      helperText={helperText}
      InputProps={{
        inputComponent: PhoneMaskAdapter,
        inputProps: {
          ref: ref,
        },
      }}
    />
  );
});

export default PhoneInput;
