import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import styled from "styled-components";
import firebaseApp, { db } from "services/firebase";
import { ocrIdCard } from "../../../API";
import Loading from "../../Loading";
import PDFViewer from "../../PDFViewer";
import Text from "../../styled/Text";
import InputField from "../../styled/InputField";
import Button from "../../styled/Button";
import Error from "../../styled/Error";

import LogoLarge from "../../styled/LogoLarge";
import ModalGeneric from "../../ModalGeneric";

const Wrapper = styled.div`
  max-width: 50em;
  padding: 2em;
  font-size: 20px;
`;

const FileUpload = styled.label`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  border-radius: 4px;
  height: 2.8em;
  font-size: 16px;
  border: 0;
  color: ${(props) => props.theme.colors.white};
  background-color: ${(props) =>
    props.$secondary
      ? props.theme.colors.secondary
      : props.gray
      ? props.theme.colors.gray
      : props.theme.colors.primary};
  margin: 0.4rem 0;
  padding: 0 1em;
  cursor: pointer;

  input[type="file"] {
    display: none;
  }
`;

const DocumentButtonWrapper = styled.div`
  label {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    border-radius: 4px;
    height: 2.8em;
    font-size: 16px;
    border: 0;
    color: ${(props) => props.theme.colors.white};
    background-color: ${(props) =>
      props.$secondary
        ? props.theme.colors.secondary
        : props.gray
        ? props.theme.colors.gray
        : props.theme.colors.primary};
    margin: 0.4rem 0;
    padding: 0 1em;
    cursor: pointer;
  }

  input[type="radio"] {
    display: none;

    &:checked + label {
      background-color: ${(props) => props.theme.colors.secondary};
    }
  }
`;

const DocumentButton = ({ label, name, register, errors, onClick }) => (
  <DocumentButtonWrapper>
    <input
      name={name}
      id={name}
      type="radio"
      onClick={onClick}
      value="true"
      ref={register}
    />
    <label htmlFor={name}>{label}</label>
    {errors[name] && <Error>{errors[name].message}</Error>}
  </DocumentButtonWrapper>
);

const PatientRegistration = ({ vaccine }) => {
  const path = window.location.pathname;
  const segments = path.split("/");
  const id = String(segments.pop()) || String(segments.pop()); // Handle potential trailing slash

  const [locationData, setLocationData] = useState({});
  const [currentQueue, setCurrentQueue] = useState();
  const [data, setData] = useState({});
  const [file, setFile] = useState();
  const [submitted, setSubmitted] = useState();
  const [loading, setLoading] = useState(false);
  const [PDF, setPDF] = useState();
  const [PDFVisible, setPDFVisible] = useState(false);
  const { register, handleSubmit, errors } = useForm();

  const [pfizerClicked, setPfizerClicked] = useState(false);
  const [modernaClicked, setModernaClicked] = useState(false);
  const [consentFormClicked, setConsentFormClicked] = useState(false);
  const [idUploaded, setIdUploaded] = useState(false);
  var tmp_id_ocr = undefined;
  const [formsViewedError, setformsViewedError] = useState(undefined);
  // const uploadPhoto = (photo, filePath) => {};

  const validateIdCard = (signature) =>
    new Promise((resolve, reject) => {
      ocrIdCard(file)
        .then((data) => {
          tmp_id_ocr = data;
          let isMatching = false;

          let text = tmp_id_ocr.responses[0].fullTextAnnotation.text;

          text = text.toUpperCase();
          text = text.replace(/(\r\n|\n|\r)/gm, "");
          text = text.replace(/[^a-zA-Z ]/g, "");
          text = text.replace(/\s\s+/g, "");
          text = text.replace(/\s/g, "");

          signature = signature.toUpperCase();
          signature = signature.replace(/[^a-zA-Z ]/g, "");
          signature = signature.replace(/\s\s+/g, "");
          signature = signature.replace(/\s/g, "");

          isMatching = text.includes(signature);

          resolve(isMatching);
        })
        .catch((er) => {
          reject(false);
          console.error(er);
        });
    });

  const checkFormCompleted = async () => {
    if (currentQueue === data.virtual_que) {
      if (currentQueue?.pfizer) {
        if (!pfizerClicked) {
          return false;
        }
      }
      if (currentQueue?.moderna) {
        if (!modernaClicked) {
          return false;
        }
      }
    }

    // if (!consentFormClicked) {
    //     return false;
    // }
    if (!idUploaded) {
      return false;
    }

    return true;
  };

  const getDates = (text) => {
    return text.match(
      /(0?[1-9]|1[012])([\/\-])(0?[1-9]|[12]\d|3[01])\2(\d{4})/g
    );
  };

  const onSubmit = async ({ email, signature }) => {
    setLoading(true);
    if (!(await checkFormCompleted())) {
      setLoading(false);
      setformsViewedError(`Please make sure you read through all of the
            required documents before submitting.`);
      return;
    }

    //TODO re enable after demoing
    //if(await validateIdCard(signature) == false) {
    //    setLoading(false);
    //    setformsViewedError(`Please make sure that your entered signature matches exactly what is printed on your ID.`);
    //    return;
    //}

    // let all_dates = getDates(
    //     tmp_id_ocr.responses[0].fullTextAnnotation.text
    // );
    // let unix_dates = [];
    // all_dates.forEach((date) => {
    //     unix_dates.push({ unix: Date.parse(date), string: date });
    // });

    // let sorted = unix_dates.sort((a, b) => (a.unix > b.unix ? 1 : -1));
    // let dob = sorted[0];

    console.log(email, signature);
    const filePath = `registrations/${data.id}.jpg`;

    const locationRef = db.collection("locations").doc(String(data.location));
    const registrationRef = db.collection("registrations").doc(id);
    const emailRef = db.collection("emails").doc(id);

    locationRef.get().then((doc) => {
      // Upload photo
      var storageRef = firebaseApp.storage().ref();

      var uploadTask = storageRef
        .child(filePath)
        .put(file)
        .then((snapshot) => {
          snapshot.ref.getDownloadURL().then((downloadUrl) => {
            console.log(downloadUrl);

            const queue = doc.data().vaccineQueue;
            const vaccineEmail = doc.data().vaccineEmail;

            const patientIndex = queue
              .map((index) => index.id)
              .indexOf(data.patient);

            queue[patientIndex].registration = data.id;
            queue[patientIndex].photoId = downloadUrl;
            const batch = db.batch();

            if (vaccineEmail) {
              batch.set(emailRef, {
                id,
                to: vaccineEmail,
                message: {
                  subject: "Patient Registration in Virtual Queue",
                  html: `<p>A patient has registered in the virtual queue.</p><p><strong>Email:</strong> ${email}<br/><strong>Signature:</strong> ${signature}<br/><strong>Photo ID:</strong> ${downloadUrl}</p>`,
                },
              });
            }

            batch.update(locationRef, { vaccineQueue: queue });
            batch.update(registrationRef, {
              email: email,
              name: signature,
              photoId: filePath,
              submitted: true,
              // dob: dob.string,
            });

            batch.commit().then(() => {
              setLoading(false);
              setSubmitted(true);
            });
          });
        });
    });
  };

  const handleImage = (event) => {
    const file = event.target.files[0];

    if (file) {
      setFile(file);
      setIdUploaded(true);
    }
  };

  useEffect(() => {
    db.collection("registrations")
      .doc(id)
      .get()
      .then((doc) => {
        setData(doc.data());
        db.collection("locations")
          .doc(String(doc.data().location))
          .get()
          .then((doc2) => {
            const data = doc2.data();
            setLocationData(data);
            setCurrentQueue(vaccine ? data.virtual_que : data.healthcare_que);
          });
      });
  }, []);

  if (submitted || data.submitted) {
    return (
      <Wrapper>
        <Text>Your registration has been submitted.</Text>
        <Link to="/vaccine">
          <Button>Return to Map</Button>
        </Link>
      </Wrapper>
    );
  }

  if (data === undefined) {
    return <Text>Page not found</Text>;
  }

  if (locationData.id) {
    return (
      <Wrapper>
        {formsViewedError ? (
          <ModalGeneric visible={formsViewedError !== undefined}>
            <p>{formsViewedError}</p>
            <Button
              onClick={() => {
                setformsViewedError(undefined);
              }}
            >
              Okay
            </Button>
          </ModalGeneric>
        ) : (
          <></>
        )}
        <LogoLarge />
        <p>
          {locationData?.title} is pleased to partner with the HealthDesk to
          provide a nearly “touchless” vaccine process. Please use the form
          below to complete your registration process, which is required prior
          to vaccination. If for any reason you are unable to complete the
          process, come in to the facility and complete the process manually.
        </p>

        <form onSubmit={handleSubmit(onSubmit)}>
          {currentQueue?.pfizer ? (
            <DocumentButton
              label="Pfizer Vaccine Information Sheet"
              name="pfizer"
              register={register({
                required: "Please read through all the documents.",
              })}
              onClick={() => {
                setPDF("/pfizer.pdf");
                setPDFVisible(true);
              }}
              errors={errors}
            />
          ) : null}

          {currentQueue?.moderna ? (
            <DocumentButton
              label="Moderna Vaccine Information Sheet"
              name="moderna"
              register={register({
                required: "Please read through all the documents.",
              })}
              onClick={() => {
                console.log("test");
                setPDF("/moderna.pdf");
                setPDFVisible(true);
              }}
              errors={errors}
            />
          ) : null}

          {locationData ? (
            <PDFViewer
              pdf={PDF}
              visible={PDFVisible}
              setVisible={setPDFVisible}
            />
          ) : null}

          {currentQueue?.forms?.map((form) => (
            <DocumentButton
              key={form.name}
              label={form.name}
              name={form.name}
              register={register({
                required: "Please read through all the documents.",
              })}
              onClick={() => {
                setPDF(form.url);
                setPDFVisible(true);
              }}
              errors={errors}
            />
          ))}
          <FileUpload $secondary={idUploaded}>
            Upload Photo ID
            <input
              type="file"
              name="photoid"
              accept="image/x-png,image/gif,image/jpeg"
              onChange={handleImage}
              ref={register({
                required: "You must upload a photo ID.",
              })}
            />
          </FileUpload>
          <Error>{errors.photoid && errors.photoid.message}</Error>

          <InputField
            name="signature"
            label="Signature (must match ID)"
            register={register({
              required: "Signature is required.",
            })}
          />
          <Error>{errors.signature && errors.signature.message}</Error>

          <InputField
            name="email"
            type="email"
            label="Email Address"
            register={register({
              required: "Email is required.",
              pattern: {
                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                message: "Invalid email address.",
              },
            })}
          />
          <Error>{errors.email && errors.email.message}</Error>

          <p>
            HealthDesk Virtual Queues reduce time in waiting rooms and may
            reduce your exposure to COVID 19. Look forward to more Virtual
            Queues throughout 2021.
          </p>
          <Button type="submit">{loading ? <Loading /> : "I Agree"}</Button>
        </form>
      </Wrapper>
    );
  }

  return <Loading page />;
};

export default PatientRegistration;
