import { useEffect } from "react";
import { useMap } from "react-leaflet/hooks";

/**
 * Component responsible for updating map view when coordinates change.
 * Synchronizes map position with selected location or user position.
 * @param {Object} coords - Target coordinates {lat, lng}
 * @param {Function} updateMap - Callback to update map position
 */
const MapUpdater = ({ coords, updateMap }) => {
  const map = useMap();

  useEffect(() => {
    if (coords) {
      const zoom = map.getZoom();
      updateMap(map, coords, zoom);
    }
  }, [coords, map, updateMap]);

  return null;
};

export default MapUpdater;
