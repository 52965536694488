import React from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";

/**
 * Reusable confirmation dialog component
 * @param {Object} props
 * @param {boolean} props.open - Dialog open state
 * @param {Function} props.onClose - Function to handle dialog close
 * @param {Function} props.onConfirm - Function to handle confirmation
 * @param {boolean} props.loading - Loading state for confirm button
 * @param {string} props.title - Dialog title (optional)
 * @param {string} props.content - Dialog content (optional)
 * @param {string} props.confirmText - Confirm button text (optional)
 * @param {string} props.cancelText - Cancel button text (optional)
 */
const ConfirmDialog = ({
  open,
  onClose,
  onConfirm,
  loading = false,
  title = "Are you sure?",
  content = "This action cannot be undone.",
  confirmText = "Confirm",
  cancelText = "Cancel",
}) => {
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <DialogContentText>{content}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button autoFocus disabled={loading} onClick={onClose}>
          {cancelText}
        </Button>
        <LoadingButton
          loading={loading}
          onClick={onConfirm}
          color="error"
          variant="contained"
        >
          {confirmText}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmDialog;
