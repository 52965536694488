import React, { useState } from "react";
import styled from "styled-components";
import { db } from "services/firebase";
import { format, differenceInMinutes } from "date-fns";
import PatientIdModal from "../styled/PatientIdModal";
import { Box, Button, Chip, IconButton, Link } from "@mui/material";
import { ClearRounded, DeleteRounded } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";

const QueuePatient = ({ data, setData, patient }) => {
  const [deleted, setDeleted] = useState(false);
  const [phoneVisible, setPhoneVisible] = useState(false);
  const [calledTime, setCalledTime] = useState();
  const [registerLoading, setRegisterLoading] = useState();

  const getDifference = () => {
    const time = calledTime ? calledTime : patient.called;
    return differenceInMinutes(Date.now(), time);
  };

  const deletePatient = (patient) => {
    setDeleted(true);
    setTimeout(() => {
      let newQueue = [...data.queue];
      const index = newQueue.indexOf(patient);
      newQueue.splice(index, 1);

      const batch = db.batch();

      batch.update(db.collection("locations").doc(String(data.id)), {
        queue: newQueue,
      });

      //This was disabled on 2-11-2021. Im gonna leave it here in case Dan changes his mind
      //if (data.queue.length > 1) {
      //  const next = newQueue.sort((a, b) => a.id - b.id)[0];
      //
      //  batch.set(db.collection("messages").doc(), {
      //    to: next.phone,
      //    body: data.textSequence
      //      ? data.textSequence[2]
      //      : `It’s almost your turn.  Please come in to ${data.title} and show this text to the welcome desk.  Note: it’s not realistic to think you’ll avoid the waiting room entirely…that’s not how most facilities work.  Still, you’ve saved yourself some time.`,
      //  });
      //}
      //
      batch.commit().then(() => {
        console.log("deleted");
      });
    }, 500);
  };

  const registerPatient = (patient) => {
    setRegisterLoading(patient.id);

    let newQueue = [...data.queue];
    const index = newQueue.indexOf(patient);
    const updatedPatient = { ...patient, registrationSent: true };
    newQueue[index] = updatedPatient;

    db.collection("registrations")
      .add({
        location: data.id,
        patient: patient.id,
      })
      .then((doc) => {
        const batch = db.batch();

        batch.update(doc, { id: doc.id });

        batch.update(db.collection("locations").doc(String(data.id)), {
          queue: newQueue,
        });

        const message =
          (data.textSequence
            ? data.textSequence[1]
            : `Please go to this link to complete the virtual registration sequence.`) +
          (data?.healthcare_que?.enabled
            ? ` ${window.location.origin}/registration/${doc.id}`
            : "");

        batch.set(db.collection("messages").doc(), {
          to: patient.phone,
          body: message,
        });

        batch.commit().then(() => {
          console.log("sent");
          setData({ ...data, queue: newQueue });
          setRegisterLoading();
        });
      });
  };

  const textPatient = (patient, type) => {
    const batch = db.batch();

    let newQueue = [...data.queue];
    const index = newQueue.indexOf(patient);
    const updatedPatient =
      type === "call"
        ? { ...patient, called: Date.now() }
        : { ...patient, registered: Date.now() };
    newQueue[index] = updatedPatient;

    batch.update(db.collection("locations").doc(String(data.id)), {
      queue: newQueue,
    });

    const message = data.textSequence
      ? data.textSequence[3]
      : `${data.title} is ready for you to come in. The address is ${data.address}. Show this text to the welcome desk.` +
        ` You are patient #${patient.id}.`;
    batch.set(db.collection("messages").doc(), {
      to: patient.phone,
      body: message,
    });

    batch.commit().then(() => {
      setData({ ...data, queue: newQueue });
    });
  };

  return (
    <Box sx={{ display: "flex", alignItems: "center", gap: "8px", mb: 1 }}>
      <Link
        onClick={() => setPhoneVisible(!phoneVisible)}
        sx={{ cursor: "pointer", flex: 1 }}
      >
        Patient #{patient.id}
      </Link>

      <Chip
        label={format(patient.date, "h:mma")}
        variant="outlined"
        color="primary"
        sx={{ flex: 1 }}
      />

      {/* Button to send the patient the registration link */}
      {/* Only show this button if Virtual Registration has been enabled */}
      {data?.healthcare_que?.enabled ? (
        <LoadingButton
          variant="contained"
          disabled={!!patient?.registration || !!patient?.registrationSent}
          loading={registerLoading === patient.id}
          onClick={() => registerPatient(patient)}
          sx={{ flex: 1 }}
        >
          {patient?.registration
            ? "Registered"
            : patient?.registrationSent
            ? "Sent"
            : "Register"}
        </LoadingButton>
      ) : null}

      <Button
        disabled={!!patient?.called}
        variant="outlined"
        onClick={() => {
          if (
            patient?.status?.toUpperCase() !== "ARRIVED" &&
            patient?.status?.toUpperCase() !== "CANCELLED"
          ) {
            textPatient(patient, "call");
            setCalledTime(Date.now());
          }
        }}
        sx={{ flex: 1 }}
      >
        {patient?.status?.toUpperCase() === "ARRIVED" ||
        patient?.status?.toUpperCase() === "CANCELLED"
          ? patient.status
          : calledTime || patient.called
          ? getDifference()
          : "Notify"}
      </Button>

      <IconButton
        color="error"
        size="small"
        onClick={() => deletePatient(patient)}
      >
        <ClearRounded fontSize="inherit" />
      </IconButton>

      <PatientIdModal
        patient={patient}
        visible={phoneVisible}
        setVisible={setPhoneVisible}
      ></PatientIdModal>
    </Box>
  );
};

export default QueuePatient;
