import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import styled from "styled-components";
import firebaseApp, { db } from "services/firebase";
import { ocrIdCard } from "../../../API";
import QuestionnaireForm from "components/vaccine/queue/QuestionnaireForm";
import Loading from "../../Loading";
import PDFViewer from "../../PDFViewer";
import Text from "../../styled/Text";
import InputField from "../../styled/InputField";
import Button from "../../styled/Button";
import Error from "../../styled/Error";

import LogoLarge from "../../styled/LogoLarge";
import ModalGeneric from "../../ModalGeneric";

const Wrapper = styled.div`
  max-width: 50em;
  padding: 2em;
  font-size: 20px;
`;

const PatientQuestionnaire = ({ vaccine }) => {
  const path = window.location.pathname;
  const segments = path.split("/");
  const id = String(segments.pop()) || String(segments.pop()); // Handle potential trailing slash

  const [locationData, setLocationData] = useState({});
  const [currentQueue, setCurrentQueue] = useState();
  const [data, setData] = useState({});
  const [submitted, setSubmitted] = useState();
  const [loading, setLoading] = useState(false);

  const [formsViewedError, setformsViewedError] = useState(undefined);

  const onSubmit = (values) => {
    setLoading(true);

    const locationRef = db.collection("locations").doc(String(data.location));
    const registrationRef = db.collection("vaccine_questionnaires").doc(id);

    locationRef.get().then((doc) => {
      const queue = doc.data().vaccineQueue;

      const patientIndex = queue.map((index) => index.id).indexOf(data.patient);

      queue[patientIndex].questionnaire = values;
      const batch = db.batch();

      batch.update(locationRef, { vaccineQueue: queue });
      batch.update(registrationRef, {
        ...values,
        submitted: true,
      });

      batch.commit().then(() => {
        setLoading(false);
        setSubmitted(true);
      });
    });
  };

  useEffect(() => {
    db.collection("vaccine_questionnaires")
      .doc(id)
      .get()
      .then((doc) => {
        setData(doc.data());
        db.collection("locations")
          .doc(String(doc.data().location))
          .get()
          .then((doc2) => {
            const data = doc2.data();
            setLocationData(data);
            setCurrentQueue(vaccine ? data.virtual_que : data.healthcare_que);
          });
      });
  }, []);

  if (submitted || data.submitted) {
    return (
      <Wrapper>
        <Text>Your registration has been submitted.</Text>
        <Link to="/vaccine">
          <Button>Return to Map</Button>
        </Link>
      </Wrapper>
    );
  }

  if (data === undefined) {
    return <Text>Page not found</Text>;
  }

  if (locationData.id) {
    return (
      <Wrapper>
        {formsViewedError ? (
          <ModalGeneric visible={formsViewedError !== undefined}>
            <p>{formsViewedError}</p>
            <Button
              onClick={() => {
                setformsViewedError(undefined);
              }}
            >
              Okay
            </Button>
          </ModalGeneric>
        ) : (
          <></>
        )}
        <LogoLarge />

        <p>
          The following questions will help us determine if there is any reason
          you should not get the COVID-19 vaccine today.{" "}
          <strong>
            If you answer “yes” to any question, it does not necessarily mean
            you should not be vaccinated.
          </strong>{" "}
          It just means additional questions may be asked. If a question is not
          clear, please ask your healthcare provider to explain it.
        </p>

        <QuestionnaireForm onSubmit={onSubmit} loading={loading} />
      </Wrapper>
    );
  }

  return <Loading page />;
};

export default PatientQuestionnaire;
