import React from "react";
import { createRoot } from "react-dom/client";
import "./index.css";
import App from "./App";
import ServiceWorkerWrapper from "./components/ServiceWorkerWrapper";

const container = document.getElementById("root");
const root = createRoot(container);
root.render(
  <>
    <ServiceWorkerWrapper />
    <App />
  </>
);
