/**
 * Map utility functions for handling wait times, scoring, and location data processing.
 */

import { differenceInHours } from "date-fns";

export const calculateWaitingScore = (score) => {
  if (score === undefined) return undefined;
  const scores = {
    1: 30,
    2: 60,
    3: 120,
    5: 150,
    7: 180,
    9: 240,
  };

  for (const [threshold, value] of Object.entries(scores)) {
    if (score <= Number(threshold)) return value;
  }
  return 360;
};

/**
 * Processes admin data from wait times and updates location object
 * @param {Object} location - Location object to update
 * @param {Array} waitTimes - Sorted array of wait time entries
 */
const processAdminData = (location, waitTimes) => {
  const adminData = waitTimes.find((time) => time.admin);
  if (adminData) {
    const { admin } = adminData;
    location.admin = admin;
  }
};

/**
 * Processes dashboard data and updates location object with facility information
 * @param {Object} location - Location object to update
 * @param {Array} waitTimes - Sorted array of wait time entries
 */
const processDashboardData = (location, waitTimes) => {
  const dashboardData = waitTimes.find((time) => time.dashboard);
  if (dashboardData) {
    const {
      customPhone,
      telehealth,
      beds,
      icu,
      lab,
      xray,
      ultrasound,
      ct,
      mri,
    } = dashboardData;
    Object.assign(location, {
      customPhone,
      telehealth,
      beds,
      icu,
      lab,
      xray,
      ultrasound,
      ct,
      mri,
    });
  }
};

/**
 * Process wait times for a location and updates the location object
 * @param {Object} location - Location object to update
 * @param {Array} adminWaitTimes - Array of admin wait times
 * @param {Array} validWaitTimes - Array of valid wait times
 * @param {Array} sortedWaitTimes - Complete sorted array of wait times
 */
const processWaitTimes = (
  location,
  adminWaitTimes,
  validWaitTimes,
  sortedWaitTimes
) => {
  location.averageWaitTime = adminWaitTimes.length
    ? getAdminTime(sortedWaitTimes)
    : getUserTime(validWaitTimes);

  location.lastUpdated = adminWaitTimes.length
    ? adminWaitTimes[0].date
    : validWaitTimes[0].date;
};

const getAdminTime = (times) => {
  const latestTime = times.find((time) => time.waitTime);
  if (latestTime.temp && differenceInHours(Date.now(), latestTime.date)) {
    return undefined;
  }
  return latestTime.waitTime;
};

const getUserTime = (times) =>
  times.reduce((sum, time) => sum + Number(time.waitTime), 0) / times.length;

export const processLocationData = (locations) => {
  const timeLimit = 60 * 60 * 1000 * 3;

  return locations.map((location) => {
    const newLocation = { ...location };
    const waitTimes = location.waitTimes;

    if (!waitTimes) {
      newLocation.waitScore = calculateWaitingScore(location.score);
      return newLocation;
    }

    const sortedWaitTimes = [...waitTimes].reverse();
    const validWaitTimes = waitTimes.filter(
      (time) => new Date() - time.date < timeLimit && new Date() > time.date
    );

    processAdminData(newLocation, sortedWaitTimes);
    processDashboardData(newLocation, sortedWaitTimes);

    if (!validWaitTimes.length) {
      newLocation.waitScore = calculateWaitingScore(location.score);
      return newLocation;
    }

    const adminWaitTimes = validWaitTimes.filter((time) => time.admin);
    processWaitTimes(
      newLocation,
      adminWaitTimes,
      validWaitTimes,
      sortedWaitTimes
    );

    return newLocation;
  });
};
