import React, { useState } from "react";
import LoginForm from "./login/LoginForm";
import AccountOptions from "./login/AccountOptions";
import FooterLinks from "./login/FooterLinks";
import LoginFormLinks from "./login/LoginFormLinks";

const Login = () => {
  const [loginFormOpen, setLoginFormOpen] = useState(false);

  return (
    <>
      <LoginForm
        loginFormOpen={loginFormOpen}
        setLoginFormOpen={setLoginFormOpen}
      />
      <LoginFormLinks loginFormOpen={loginFormOpen} />

      <AccountOptions />

      <FooterLinks />
    </>
  );
};

export default Login;
